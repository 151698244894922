import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { css } from 'emotion'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Title from '../components/Title'
import SEO from '../components/SEO'
import Share from '../components/Share'
import Img from 'gatsby-image'

const main = css({
  '.gif': {
    maxHeight: '400px',
    margin: '0 auto'
  },

  '@media all and (max-width: 450px)': {
    '.column': {
      paddingBlockEnd: '0 !important',
      paddingBlockStart: '0 !important'
    },
  },
})

export const AboutCafTemplate = ({ title, content, contentComponent, colaborators, helmet, slug, img1, img2, img3 }) => {
  const PageContent = contentComponent || Content

  let half = Math.floor(colaborators.girls.length / 2);
  let smLeft = colaborators.girls.slice(0, half);
  let smRight = colaborators.girls.slice(half, colaborators.girls.length);
  let words = [];

  if (typeof content === 'string') {
    words = content.split('<p>');
    words = words.map(w => w.replace(/<[^>]+>/g, ''))
    words = words.filter(x => (x !== (undefined || null || '')));
  }

  return (
    <section className={main}>
      {helmet || ''}
      <div className="content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <Share title={title} slug={slug} />
            <div className="section">
              <Title title={title} />
              {typeof content !== 'string' && <PageContent content={content} />}
              <div className="columns is-vcentered" style={{ marginTop: '3em' }}>
                <div className="column center-text">
                  <p>{words[0]}</p>
                </div>
                <div className="column is-two-thirds">
                  {img1 && <Img fluid={img1.childImageSharp.fluid} />}
                </div>
              </div>
              <div className="columns is-vcentered">
                <div className="column  is-two-thirds">
                  {img2 && <Img fluid={img2.childImageSharp.fluid} />}
                </div>
                <div className="column center-text">
                  <p>{words[1]}</p>
                </div>
              </div>
              <div className="columns is-vcentered">
                <div className="column center-text">
                  <p>{words[2]}</p>
                </div>
                <div className="column is-two-thirds">
                  {img3 && <Img fluid={img3.childImageSharp.fluid} />}
                </div>
              </div>
              <h2 className="has-text-centered">{words[3]}</h2>
              <div className="has-text-centered">
                <img className="gif" src="https://res.cloudinary.com/chicasalfrente/image/upload/v1544224812/caf.gif" alt="caf" />
              </div>
              <h3 className="has-text-centered">{words[4]}</h3>
              <div className="columns is-vcentered">
                <div className="column">
                  {smRight &&
                    smRight.map((colaborator, i) => <div key={i}>
                      <span style={{ fontWeight: 'bold' }}>&bull; {`${colaborator.name}: `}</span><span>{colaborator.bio}</span>
                    </div>)
                  }
                </div>
                <div className="column">
                  {smLeft &&
                    smLeft.map((colaborator, i) => <div key={i}>
                      <span style={{ fontWeight: 'bold' }}>&bull; {`${colaborator.name}: `}</span><span>{colaborator.bio}</span>
                    </div>)
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

AboutCafTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string,
  content: PropTypes.string,
  colaborators: PropTypes.object,
  contentComponent: PropTypes.func,
}

const AboutCaf = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <AboutCafTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        colaborators={post.frontmatter.colaborators}
        img1={post.fields.img1}
        img2={post.fields.img2}
        img3={post.fields.img3}
        helmet={<SEO
          isBlogPost={false}
          postData={{ ...post, description: 'Chicas al Frente es una iniciativa sororal que realiza eventos mensuales con el fin de concientizar bajo una perspectiva de género' }}
          postImage={post.fields.img1.childImageSharp.fluid.src}
        />}
        slug={post.fields.slug}
      />
    </Layout>
  )
}

AboutCaf.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutCaf

export const AboutCafQuery = graphql`
query AboutCaf($id: String!) {
  markdownRemark(id: {eq: $id}) {
    html
    fields {
      slug
      img1 {
        childImageSharp {
          fluid {
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
            originalImg
            originalName
            presentationWidth
            presentationHeight
          }
        }
      }
      img2 {
        childImageSharp {
          fluid {
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
            originalImg
            originalName
            presentationWidth
            presentationHeight
          }
        }
      }
      img3 {
        childImageSharp {
          fluid {
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
            originalImg
            originalName
            presentationWidth
            presentationHeight
          }
        }
      }
    }
    frontmatter {
      title
      colaborators {
        girls {
          bio
          name
        }
      }
    }
  }
}

`
